<template>
	<v-sheet class="document" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="3" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="true"
					:items="listingStatusArr"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="9" class="py-1 my-auto text-right listing-right">
				<v-layout class="justify-end">
					<div class="d-flex">
						<v-btn
							v-if="getPermission('document:create')"
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							v-on:click="add_document_dialog = true"
						>
							<v-icon left> mdi-upload </v-icon>
							Upload
						</v-btn>
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('document')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('document')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</div>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="File Name, File Remark, Customer # and Customer Display Name"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="document"
			disable-edit
			delete-endpoint="document/"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this document will also be deleted."
		></Table>
		<ExportDialog
			endpoint="document/export?type=document&current=1"
			title="Orders"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="document/export?type=document"
			title="Orders"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="document/import?type=document"
			title="Orders"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Orders"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<CreateDocument
			:document-dialog="add_document_dialog"
			v-on:close="add_document_dialog = false"
			v-on:success="getListing()"
		>
		</CreateDocument>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import CreateDocument from "@/view/components/CreateDocument";
import { mapGetters } from "vuex";

export default {
	name: "document-listing",
	title: "Listing Documents",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Documents",
			listingStatusArr: [
				{
					id: 10,
					type: "document",
					text: "All Documents",
					value: "all",
					description: "List of All Documents",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			pageBreadcrumbs: [{ text: "Documents", disabled: true }],
			endpoint: "documents",
			defaultFilter: {},
			add_document_dialog: false,
		};
	},
	components: {
		CreateDocument,
	},
	methods: {
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
	},
	mounted() {
		this.incharge_items = this.localDB("members", []);
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
